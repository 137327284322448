<template>
  <div>
    <h1>Võrdlustabeli reeglite haldus</h1>
    <div class="mb-2">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-text-field
            v-model="title"
            label="Pealkiri"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            outlined
            label="Kindlustusliik"
            v-model="insurance_type"
            :items="insurance_types"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="recommendation_weight"
            label="Kaal soovituse arvutamisel"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-select
            outlined
            label="Keel"
            v-model="lang"
            :items="languages"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <QueryBuilder
      :query="query"
      :lang="lang"
    > </QueryBuilder>
    <v-btn
      v-show="true"
      dark
      depressed
      color="#3d9732"
      @click="save"
      class="mt-5"
    >
      Salvesta
    </v-btn>
  </div>
</template>

<script>
  import axios from "axios";
  import QueryBuilder from '../components/QueryBuilder/QueryBuilder';

  export default {
    name: "Conditions",
    components: {
      QueryBuilder
    },
    data() {
      return {
        title: '',
        insurance_type: '',
        recommendation_weight: 0,
        lang: 'ET',
        query: {
          logicalOperator: 'AND',
          rules: [
            {
              "rule": "",
              "operator": "equal",
              "value": ""
            }
          ],
          tooltip: {
            "ET": "",
            "EN": "",
            "RU": "",
          },
          groups: []
        },
        insurance_types: [
          {
            text: 'Kasko',
            value: 'casco'
          },
          {
            text: 'Liikluskindlustus',
            value: 'mtpl'
          },
          {
            text: 'Kodukindlustus',
            value: 'home'
          },
          {
            text: 'Reisikindlustus',
            value: 'travel'
          }
        ],
        languages: [
          {
            text: 'Eesti',
            value: 'ET'
          },
          {
            text: 'Inglise',
            value: 'EN'
          },
          {
            text: 'Vene',
            value: 'RU'
          },
        ]
      };
    },
    methods: {
      save: function () {
        let payload = {
          title: this.title,
          insurance_type: this.insurance_type,
          recommendation_weight:  this.recommendation_weight,
          config: this.query
        };
        let url = axios.defaults.baseURL + '/api/conditions';
        axios.post(url, payload).then((response) => {
          this.$router.push('/conditions');
        });
      }
    }
  }
</script>

<style scoped>

</style>